import { create } from "zustand";
import { STATUS } from "../../Constant";
import { apis } from "../../services/apis/apis";
import { ErrorAlert, SuccessAlert } from "../../utils/Helper";

export const useLoginStore = create((set, get) => ({
    resetStatus: async () => {
        set({
            userLoginStatus: STATUS.NOT_STARTED,
        })
    },

    resetPassStatus: async () => {
        set({
            changePassStatus: STATUS.NOT_STARTED,
        })
    },

    resetPinStatus: async () => {
        set({
            changePinStatus: STATUS.NOT_STARTED,
        })
    },

    loginUserAction: async (payload) => {
        set({ userLoginStatus: STATUS.FETCHING });
        const { data } = await apis.loginApi(payload);
        if (data.action === true) {
            set({
                userLogin: data,
                userLoginStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ userLoginStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    changePassAction: async (payload) => {
        set({ changePassStatus: STATUS.FETCHING });
        const { data } = await apis.changePassApi(payload);
        if (data.action === true) {
            set({
                changePass: data,
                changePassStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ changePassStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    changePinAction: async (payload) => {
        set({ changePinStatus: STATUS.FETCHING });
        const { data } = await apis.changePinApi(payload);
        if (data.action === true) {
            set({
                changePin: data,
                changePinStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ changePinStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },
}))