import { Button, Flex, HStack, PinInput, PinInputField, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useZwitchStore } from "../../store/Zwitch";
import { STATUS } from "../../Constant";
import { TbTransferOut } from "react-icons/tb";
import { MdCurrencyRupee } from "react-icons/md";
import dayjs from "dayjs";

export const VerifyOTP = ({ bank, data, closeModal, handleToggle, setToggleVerifyOtp }) => {
    const [otp, setOtp] = useState(null)
    const [transactions, setToggleTransationDetail] = useState(null)

    const inputHandler = (otp) => {
        setOtp(otp)
    }

    const { bankTransferAction, bankTransferStatus, bankTransfer,
        upiTransferAction, upiTransferStatus, upiTransfer,
        resetTransferData, resetTransferStatus,
        verifyMpinAction, verifyMpinStatus, resetMpinStatus
    } = useZwitchStore(s => ({
        bankTransferAction: s.bankTransferAction,
        bankTransferStatus: s.bankTransferStatus,
        bankTransfer: s.bankTransfer,
        upiTransferAction: s.upiTransferAction,
        upiTransferStatus: s.upiTransferStatus,
        upiTransfer: s.upiTransfer,
        resetTransferData: s.resetTransferData,
        resetTransferStatus: s.resetTransferStatus,
        verifyMpinAction: s.verifyMpinAction,
        verifyMpinStatus: s.verifyMpinStatus,
        resetMpinStatus: s.resetMpinStatus
    }))

    const verifyOtp = (e) => {
        e.preventDefault()
        verifyMpinAction({ mpin: otp })
    }

    useEffect(() => {
        if (verifyMpinStatus === STATUS.SUCCESS && bank) {
            resetMpinStatus()
            if (bank?.accountNo) {
                bankTransferAction({
                    ...data,
                    bankId: bank.id,
                    accountNo: bank.accountNo
                })
            }
            else {
                upiTransferAction({
                    ...data,
                    upiId: bank.id,
                    upi: bank.upiId
                })
            }
        }
    }, [bank, bankTransferAction, data, resetMpinStatus, upiTransferAction, verifyMpinStatus])

    useEffect(() => {
        if (bankTransferStatus === STATUS.SUCCESS || upiTransferStatus === STATUS.SUCCESS) {
            resetTransferStatus()
            setToggleTransationDetail(bankTransfer ? bankTransfer : upiTransfer)
            handleToggle(null)
        }
    }, [bankTransfer, bankTransferStatus, closeModal, handleToggle, resetTransferStatus, setToggleVerifyOtp, upiTransfer, upiTransferStatus])

    const Exit = () => {
        resetTransferStatus()
        resetTransferData()
        closeModal()
        setToggleVerifyOtp(null)
    }
    return (
        <>
            {transactions ?
                <Flex flexDir={"column"}>
                    <Flex align={"center"}>
                        <TbTransferOut fontSize={16} />
                        <Text ml={2} fontSize={16} >Debit</Text>
                    </Flex>

                    <Flex mt={2} align={"center"}>
                        <MdCurrencyRupee />
                        <Text fontSize={26} fontWeight={"semibold"}>{transactions.amount}</Text>
                    </Flex>
                    <Flex fontSize={12}>
                        <Text w={"40%"}>Transaction Id</Text>
                        <Text>: &nbsp;&nbsp;{transactions.id}</Text>
                    </Flex>
                    <Flex fontSize={12}>
                        <Text w={"40%"}>Date</Text>
                        <Text>: &nbsp;&nbsp;{dayjs(new Date(transactions.created_at * 1000)).format("DD MMM YYYY  hh:mm A")}</Text>
                    </Flex>
                    <Text mt={3} fontSize={12} fontWeight={"semibold"} borderTop={"1px solid"} py={1} borderColor={"gray.200"}>Credited To</Text>
                    <Flex fontSize={12}>
                        <Text w={"40%"}>{transactions?.payment_mode === "upi" ? "UPI ID" : "Account No"}</Text>
                        <Text>: &nbsp;&nbsp;{transactions.paid_to}</Text>
                    </Flex>
                    <Flex w={"100%"} mt={5} justifyContent={"flex-end"}>
                        <Button size={"sm"} colorScheme={"blue"} onClick={Exit}>OK</Button>
                    </Flex>
                </Flex>
                :
                < Flex flexDir={"column"} align={"center"} >
                    <Text fontSize={16} mt={4}>Enter the 6-Digit MPin</Text>
                    <Text fontSize={13}>Be careful not to share share mpin with anyone</Text>
                    <form onSubmit={verifyOtp} style={{ width: "100%" }}>
                        <HStack my={10} w={"100%"} justify={"center"}>
                            <PinInput w={"100%"} otp autoFocus focusBorderColor={"blue.400"} borderColor={"gray.400"} onChange={(e) => inputHandler(e)}>
                                <PinInputField borderColor={"gray.400"} />
                                <PinInputField borderColor={"gray.400"} />
                                <PinInputField borderColor={"gray.400"} />
                                <PinInputField borderColor={"gray.400"} />
                                <PinInputField borderColor={"gray.400"} />
                                <PinInputField borderColor={"gray.400"} />
                            </PinInput>
                        </HStack>
                        <Flex w={"100%"} mt={5} justifyContent={"space-between"}>
                            <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={() => setToggleVerifyOtp(null)}>Back</Button>
                            <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={verifyMpinStatus === STATUS.FETCHING || bankTransferStatus === STATUS.FETCHING || upiTransferStatus === STATUS.FETCHING}>Pay</Button>
                        </Flex>
                    </form>
                </Flex >
            }
        </>
    )
}