import { Box, Flex } from "@chakra-ui/react"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"

export const MainLayout = ({ children }) => {
    return (
        <Box h="100vh" bg={"lightDark"}>
            <Header />
            <Flex h={"85vh"}>
                <Box display={{ base: "none", md: "initial" }} w={{ base: "24vw", xl: "15vw" }}>
                    <Sidebar />
                </Box>
                <Box w={{ base: "100vw", md: "76vw", xl: "85vw" }} bg={"lightDark"} p={2}>
                    {children}
                </Box>
            </Flex>
        </Box>
    )
}