import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import { Dashboard } from "./Components/dashboard";
import { LoginUser } from "./Components/login";
import { MainLayout } from "./layout/MainLayout";
import { CreateContact } from "./Components/Payout/createContact";
import { ContactList } from "./Components/Payout/ContactList";
import { getLocalStorageItem } from "./utils/LocalStorage";
import { Ledger } from "./Components/Ledger";
import { ChangePassword } from "./Components/ChangePass";
import { ChangeMPin } from "./Components/ChangeMPin";
// import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   const clearLocalStorageOnTabClose = (event) => {
  //     localStorage.clear();
  //   };
  //   window.addEventListener('beforeunload', clearLocalStorageOnTabClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', clearLocalStorageOnTabClose);
  //   };
  // }, []);

  const routerConfig = [
    { path: "/", element: <MainLayout><Dashboard /></MainLayout>, authRequired: true },
    { path: "/login", element: <LoginUser /> },
    { path: "/contact-list", element: <MainLayout><ContactList /></MainLayout>, authRequired: true },
    { path: "/create-contact", element: <MainLayout><CreateContact /></MainLayout>, authRequired: true },
    { path: "/ledger", element: <MainLayout><Ledger /></MainLayout>, authRequired: true },
    { path: "/change-password", element: <MainLayout><ChangePassword /></MainLayout>, authRequired: true },
    { path: "/change-mpin", element: <MainLayout><ChangeMPin /></MainLayout>, authRequired: true },
  ]


  function isAuthenticated() {
    const accessToken1 = getLocalStorageItem("accessToken")
    return accessToken1
  }

  function createBrowserRouterWithAuth(routes) {
    return createBrowserRouter(
      routes.map(route => {
        if (route.authRequired) {
          return {
            ...route,
            element: isAuthenticated() ? route.element : <Navigate to="/login" />,
          };
        }
        return route;
      })
    );
  }

  const router = createBrowserRouterWithAuth(routerConfig);

  return (
    <html lang="en">
      <head>
        <link rel="icon" href="/public/assets/favicon.png" />
        <title>Vijay Library Pay</title>
      </head>
      <body>
        <RouterProvider router={router} />
      </body>
    </html>
  );
}

export default App;
