import { DeleteIcon } from "@chakra-ui/icons"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, IconButton, Tooltip } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { STATUS } from "."

export const DeleteButton = ({ heading, description, confirm, status, reset, label }) => {
    const [toggleConfirm, setToggleConfirm] = useState(null)

    useEffect(() => {
        if (status === STATUS.SUCCESS) {
            reset()
            setToggleConfirm(null)
        }
    }, [reset, status])

    return (
        <>
            <Tooltip placement="top" label={label || "Delete"}>
                <IconButton size={"xs"} variant={"ghost"} icon={<DeleteIcon />} colorScheme={"red"} onClick={() => setToggleConfirm([])} />
            </Tooltip>
            {toggleConfirm &&
                <ConfirmAlert
                    heading={heading}
                    description={description}
                    confirm={confirm}
                    closeAlert={() => setToggleConfirm(null)}
                />
            }
        </>
    )
}

const ConfirmAlert = ({ heading, description, closeAlert, confirm, status }) => {

    return (
        <Modal isOpen={true} onClose={closeAlert} isCentered closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{heading || "Delete Confirmation"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{description || "Are you sure? Do you want delete?"}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" variant={"outline"} colorScheme={"blue"} mr={3} onClick={closeAlert}>
                        Close
                    </Button>
                    <Button size="sm" colorScheme={"red"} isLoading={status} onClick={confirm}>Delete</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}