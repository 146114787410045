import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    styles: {
        global: {
            ".chakra-table": {
                width: "100%",
                borderCollapse: "collapse",
                // fontFamily: "Arial, sans-serif",
            },
            ".chakra-table th, .chakra-table td": {
                padding: 2,
                border: "1px solid #ddd",
            },
            ".chakra-table th": {
                fontSize: 12,
                backgroundColor: "#1d2345",
                color: "white",
            },
            ".chakra-table td": {
                fontSize: 13,
                fontWeight: "semibold"
            },
            //   ".chakra-table tr:nth-child(even)": {
            //     backgroundColor: "#f2f2f2",
            //   },
        },
    },
    colors: {
        defaultWhite: "#e3e3e3",
        lightDark: "#151f44",
        cardDark: "#1d2345",
        subCardDark: "#202648",
        defaultDark: "#111739",
        borderWhite: "rgba(255, 255, 255, 0.24)"
    },
});

export default theme;
