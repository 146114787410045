import { create } from "zustand";
import { STATUS } from "../../Constant";
import { apis } from "../../services/apis/apis";
import { ErrorAlert, SuccessAlert } from "../../utils/Helper";

export const useZwitchStore = create((set, get) => ({
    resetVerify: async () => {
        set({
            verifyBankStatus: STATUS.NOT_STARTED,
            verifyUpiStatus: STATUS.NOT_STARTED,
        })
    },

    resetAddBeneficiary: async () => {
        set({
            addBenificiaryBankStatus: STATUS.NOT_STARTED,
            addBenificiaryUpiStatus: STATUS.NOT_STARTED,
        })
    },

    resetDeleteStatus: async () => {
        set({
            deleteBankStatus: STATUS.NOT_STARTED,
            deleteUpiStatus: STATUS.NOT_STARTED
        })
    },

    resetTransferStatus: async () => {
        set({
            bankTransferStatus: STATUS.NOT_STARTED,
            upiTransferStatus: STATUS.NOT_STARTED
        })
    },
    resetMpinStatus: async () => {
        set({
            reqOtpStatus: STATUS.NOT_STARTED,
            verifyMpinStatus: STATUS.NOT_STARTED
        })
    },
    resetTransferData: async () => {
        set({
            bankTransfer: null,
            upiTransfer: null
        })
    },
    resetReferenceStatus: async () => {
        set({
            verifyReferenceStatus: STATUS.NOT_STARTED,
        })
    },

    dashboardAction: async (payload) => {
        set({ dashboardStatus: STATUS.FETCHING });
        const { data } = await apis.dashboardApi(payload);
        if (data.action === true) {
            set({
                dashboard: data,
                dashboardStatus: STATUS.SUCCESS
            })
        } else {
            set({ dashboardStatus: STATUS.FAILED })
            ErrorAlert(data?.message)
        }
    },

    transactionAction: async (payload) => {
        set({ transactionStatus: STATUS.FETCHING });
        const { data } = await apis.transactionApi(payload);
        if (data.action === true) {
            set({
                transactions: data.data,
                transactionStatus: STATUS.SUCCESS
            })
        } else {
            set({ transactionStatus: STATUS.FAILED })
            ErrorAlert(data?.message)
        }
    },

    verifyBankAction: async (payload) => {
        set({ verifyBankStatus: STATUS.FETCHING });
        const data = await apis.verifyBankApi(payload);
        console.log(data);
        if (data?.id) {
            set({
                verifyBank: data,
                verifyBankStatus: STATUS.SUCCESS
            })
        } else {
            set({ verifyBankStatus: STATUS.FAILED })
            ErrorAlert(data?.message)
        }
    },

    addBenificiaryBankAction: async (payload) => {
        set({ addBenificiaryBankStatus: STATUS.FETCHING });
        const data = await apis.addBenificiaryBankApi(payload);
        console.log(data);
        if (data?.id) {
            set({
                addBenificiaryBank: data,
                addBenificiaryBankStatus: STATUS.SUCCESS
            })
        } else {
            set({ addBenificiaryBankStatus: STATUS.FAILED })
            ErrorAlert(data?.message)
        }
    },

    verifyUpiAction: async (payload) => {
        set({ verifyUpiStatus: STATUS.FETCHING });
        const data = await apis.verifyUpiApi(payload);
        console.log(data);
        if (data) {
            set({
                verifyUpi: data,
                verifyUpiStatus: STATUS.SUCCESS
            })
        } else {
            set({ verifyUpiStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    addBenificiaryUpiAction: async (payload) => {
        set({ addBenificiaryUpiStatus: STATUS.FETCHING });
        const data = await apis.addBenificiaryUpiApi(payload);
        console.log(data);
        if (data) {
            set({
                addBenificiaryUpi: data,
                addBenificiaryUpiStatus: STATUS.SUCCESS
            })
        } else {
            set({ addBenificiaryUpiStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    bankTransferAction: async (payload) => {
        set({ bankTransferStatus: STATUS.FETCHING });
        const { data } = await apis.bankTransferApi(payload);
        if (data.action === true) {
            console.log(data);
            set({
                bankTransfer: data.data,
                bankTransferStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ bankTransferStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    upiTransferAction: async (payload) => {
        set({ upiTransferStatus: STATUS.FETCHING });
        const { data } = await apis.upiTransferApi(payload);
        if (data.action === true) {
            console.log(data);
            set({
                upiTransfer: data.data,
                upiTransferStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ upiTransferStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    verifyMpinAction: async (payload) => {
        set({ verifyMpinStatus: STATUS.FETCHING });
        const { data } = await apis.verifyMpinApi(payload);
        if (data.action === true) {
            set({
                verifyMpin: data.data,
                verifyMpinStatus: STATUS.SUCCESS
            })
        } else {
            set({ verifyMpinStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    verifyReferenceAction: async (payload) => {
        set({ verifyReferenceStatus: STATUS.FETCHING });
        const { data } = await apis.verifyReferenceApi(payload);
        if (data.status === "true") {
            set({
                verifyReference: data.status,
                verifyReferenceStatus: STATUS.SUCCESS
            })
        } else {
            set({ verifyReferenceStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },
}))