import { AddIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Tag, Text } from "@chakra-ui/react";
import { map, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { FundAccount } from "./FundAccount";
import { useNavigate } from "react-router-dom";
import { useContactStore } from "../../store/Contact";
import { STATUS } from "../../Constant";

export const ContactList = () => {
    const navigate = useNavigate()
    const [toggleModal, setToggleModal] = useState(null)
    const { getContactAction, getContactStatus, allContacts } = useContactStore(s => ({
        getContactAction: s.getContactAction,
        getContactStatus: s.getContactStatus,
        allContacts: s.allContacts
    }))

    useEffect(() => {
        if ((getContactStatus || 1) === STATUS.NOT_STARTED) {
            getContactAction()
        }
    }, [allContacts, getContactAction, getContactStatus])

    return (
        <Box p={5} bg={"defaultDark"} h={"100%"} className="scrollBar" maxH={"100%"} overflowY={"scroll"}>
            <Flex my={5} w={"100%"} justify={"space-between"} align={"center"}>
                <Text fontSize={{ base: 18, md: 20 }} fontWeight={"semibold"} color={"defaultWhite"}>Select Contact</Text>
                <Button size={{ base: "xs", md: "sm" }} colorScheme="blue" leftIcon={<AddIcon />} onClick={() => navigate("/create-contact")}>New Contact</Button>
            </Flex>

            {map(orderBy(allContacts?.data, "createdAt", "desc"), contact => (
                <Flex my={3} key={contact.id} _hover={{ bg: "lightDark" }} px={4} py={2} borderRadius={10} align={"center"} cursor={"pointer"} onClick={() => setToggleModal(contact)} border={"1px solid"} borderColor={"borderWhite"}>
                    <Box w={"50%"}>
                        <Text fontSize={16} fontWeight={"semibold"} color={"defaultWhite"}>{contact.name}</Text>
                        <Flex flexDir={{ base: "column", md: "row" }} mt={2} fontSize={12} color={"whiteAlpha.700"}>
                            <Flex align={"center"} w={"50%"} pr={4}>
                                <PhoneIcon />
                                <Text ml={2}>{contact.mobileNo}</Text>
                            </Flex>
                            <Flex align={"center"} w={"50%"}>
                                <EmailIcon />
                                <Text ml={2}>{contact.email}</Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <Flex w={"50%"} justify="flex-end">
                        <Tag size={"sm"} fontSize={10}>{contact?.type || "Customer"}</Tag>
                    </Flex>
                </Flex>
            ))}
            {toggleModal && <FundAccount contact={toggleModal} closeModal={() => setToggleModal(null)} />}
        </Box>
    )
}