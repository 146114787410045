import { TbNewSection } from "react-icons/tb";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";

export const SidebarItems = [
    {
        name: "Dashboard",
        icon: <MdDashboard />,
        key: "",
        href: "/",
    },
    {
        name: "Contact List",
        icon: <TbNewSection />,
        key: "contact-list",
        href: "/contact-list",
    },
    {
        name: "Ledger",
        icon: <AiOutlineTransaction />,
        key: "ledger",
        href: "/ledger",
    },
]