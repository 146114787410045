import { Box, Button, Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react"
import { useState } from "react"
import { useContactStore } from "../../store/Contact"
import { STATUS } from "../../Constant"

export const BankAccount = ({ contact, setAddFund }) => {

    const [inputValue, setInputValue] = useState({})
    const [error, setError] = useState(null)

    const { addBankAction, addBankStatus } = useContactStore(s => ({
        addBankAction: s.addBankAction,
        addBankStatus: s.addBankStatus
    }))

    const saveBankAccount = (e) => {
        e.preventDefault()
        if (inputValue.accountNo === inputValue.accountNo1) {
            addBankAction({
                contactId: contact.id,
                type: "saving",
                ...inputValue
            })
        }
        else {
            setError("Account No. must be same")
        }
    }
    return (
        <Box>
            <form onSubmit={saveBankAccount}>
                <InputForm label={"Account No."} type={"number"} limit={17} name={"accountNo"} inputValue={inputValue} setInputValue={setInputValue} />
                <InputForm label={"Confirm Account No."} type={"number"} limit={17} name={"accountNo1"} inputValue={inputValue} setInputValue={setInputValue} />
                <Flex justify={"flex-end"}>
                    <Text w={"60%"} fontSize={12} color="red">{error}</Text>
                </Flex>
                <InputForm label={"IFSC"} type={"text"} name={"ifsc"} inputValue={inputValue} setInputValue={setInputValue} />
                <InputForm label={"Beneficiary Name"} type={"text"} name={"beneficiaryName"} inputValue={inputValue} setInputValue={setInputValue} />
                <Flex w={"100%"} mt={5} justifyContent={"space-between"}>
                    <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={() => setAddFund(false)}>Back</Button>
                    <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={addBankStatus === STATUS.FETCHING}>Add</Button>
                </Flex>
            </form>
        </Box>
    )
}

const InputForm = ({ name, label, limit, type, inputValue, setInputValue, w, focus }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired>
            <Flex py={3} align={"center"}>
                <FormLabel w={w || "65%"} fontSize={13}>{label}</FormLabel>
                <Input size={"sm"} autoFocus={focus} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} borderColor={"gray.400"} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}