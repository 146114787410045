import { create } from "zustand";
import { STATUS } from "../../Constant";
import { apis } from "../../services/apis/apis";
import { filter } from "lodash";
import { ErrorAlert, SuccessAlert } from "../../utils/Helper";

export const useContactStore = create((set, get) => ({
    resetAddStatus: async () => {
        set({
            addContactStatus: STATUS.NOT_STARTED,
            addUpiStatus: STATUS.NOT_STARTED,
            addBankStatus: STATUS.NOT_STARTED
        })
    },

    reset: async () => {
        set({
            getContactStatus: STATUS.NOT_STARTED
        })
    },

    resetDeleteStatus: async () => {
        set({
            deleteBankStatus: STATUS.NOT_STARTED,
            deleteUpiStatus: STATUS.NOT_STARTED,
            deleteAllFundsStatus: STATUS.NOT_STARTED,
        })
    },

    getContactAction: async (payload) => {
        set({ getContactStatus: STATUS.FETCHING });
        const { data } = await apis.getContactApi(payload);
        if (data.action === true) {
            set({
                allContacts: data,
                getContactStatus: STATUS.SUCCESS
            })
        } else {
            set({ getContactStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    addContactAction: async (payload) => {
        set({ addContactStatus: STATUS.FETCHING });
        const data = await apis.addContactApi(payload);
        if (data?.data?.action === true) {
            const prev = get().allContacts
            console.log(prev);
            set({
                allContacts: {
                    ...prev, data: [...(prev?.data ? prev?.data : []), data.data.data]
                },
                addContactStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.data.message)
        } else {
            set({ addContactStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.message)
        }
    },

    getContactBanks: async (payload) => {
        set({ contactBanksStatus: STATUS.FETCHING });
        const { data } = await apis.getContactBanksApi(payload);
        if (data.action === true) {
            set({
                contactBanks: data,
                contactBanksStatus: STATUS.SUCCESS
            })
        } else {
            set({ contactBanksStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    addBankAction: async (payload) => {
        set({ addBankStatus: STATUS.FETCHING });
        const { data } = await apis.addBankApi(payload);
        if (data.action === true) {
            const prev = get().contactBanks
            set({
                contactBanks: { ...prev, bank: [...prev.bank, data.data] },
                addBankStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ addBankStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    deleteBankAction: async (payload) => {
        set({ deleteBankStatus: STATUS.FETCHING });
        const { data } = await apis.deleteBankApi(payload);
        if (data.action === true) {
            const prev = get().contactBanks
            set({
                contactBanks: { ...prev, bank: filter(prev.bank, b => b.id !== payload) },
                deleteBankStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ deleteBankStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    addUpiAction: async (payload) => {
        set({ addUpiStatus: STATUS.FETCHING });
        const data = await apis.addUpiApi(payload);
        if (data?.data?.action === true) {
            const prev = get().contactBanks
            set({
                contactBanks: { ...prev, upi: [...prev.upi, data.data.data] },
                addUpiStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ addUpiStatus: STATUS.FAILED })
            ErrorAlert(data?.data?.message)
        }
    },

    deleteUpiAction: async (payload) => {
        set({ deleteUpiStatus: STATUS.FETCHING });
        const { data } = await apis.deleteUpiApi(payload);
        if (data.action === true) {
            const prev = get().contactBanks
            set({
                contactBanks: { ...prev, upi: filter(prev.upi, b => b.id !== payload) },
                deleteUpiStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ deleteUpiStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },

    deleteAllFundsAction: async (payload) => {
        set({ deleteAllFundsStatus: STATUS.FETCHING });
        const { data } = await apis.deleteAllFundsApi(payload);
        if (data.action === true) {
            const prev = get().contactBanks
            set({
                contactBanks: { ...prev, bank: [], upi: [] },
                deleteAllFundsStatus: STATUS.SUCCESS
            })
            SuccessAlert(data.message)
        } else {
            set({ deleteAllFundsStatus: STATUS.FAILED })
            ErrorAlert(data.message)
        }
    },
}))