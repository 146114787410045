import { Box, Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react"
import { useState } from "react"
import { useContactStore } from "../../store/Contact"
import { STATUS } from "../../Constant"

export const UpiAccount = ({ contact, setAddFund }) => {
    const [inputValue, setInputValue] = useState({})

    const { addUpiAction, addUpiStatus } = useContactStore(s => ({
        addUpiAction: s.addUpiAction,
        addUpiStatus: s.addUpiStatus
    }))

    const saveUpiAccount = (e) => {
        e.preventDefault()
        addUpiAction({
            contactId: contact.id,
            ...inputValue
        })
    }

    return (
        <Box>
            <form onSubmit={saveUpiAccount}>
                <InputForm label={"UPI ID"} type={"text"} name={"upiId"} inputValue={inputValue} setInputValue={setInputValue} />
                <Flex w={"100%"} mt={5} justifyContent={"space-between"}>
                    <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={() => setAddFund(false)}>Back</Button>
                    <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={addUpiStatus === STATUS.FETCHING}>Add</Button>
                </Flex>
            </form>
        </Box>
    )
}

const InputForm = ({ name, label, limit, type, inputValue, setInputValue, w, focus }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired>
            <Flex py={3} align={"center"}>
                <FormLabel w={w || "65%"} fontSize={13}>{label}</FormLabel>
                <Input size={"sm"} autoFocus={focus} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} borderColor={"gray.400"} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}