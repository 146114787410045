import { Button, Flex, FormControl, FormLabel, Input, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContactStore } from "../../store/Contact";
import { STATUS } from "../../Constant";

export const CreateContact = () => {

    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState({})

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }
    const { addContactAction, addContactStatus, resetAddStatus } = useContactStore(s => ({
        addContactAction: s.addContactAction,
        addContactStatus: s.addContactStatus,
        resetAddStatus: s.resetAddStatus
    }))

    const submitData = (e) => {
        e.preventDefault()
        addContactAction(inputValue)
    }
    const goBack = () => {
        navigate("/contact-list")
    }

    useEffect(() => {
        if (addContactStatus === STATUS.SUCCESS) {
            resetAddStatus()
            goBack()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addContactStatus, resetAddStatus])

    return (
        <Flex w={"100%"} h={"100%"} flexDir={"column"} align={"center"} bg={"defaultDark"} color={"defaultWhite"}>
            <Flex w={{ base: "95%", md: "70%", xl: "50%" }} flexDir={"column"} p={{ base: 2, md: 5 }} >
                <Text my={5} fontSize={20} fontWeight={"semibold"}>Add New Contact</Text>

                <form onSubmit={submitData}>
                    <InputForm name={"name"} label={"Contact Name"} type={"text"} inputValue={inputValue} setInputValue={setInputValue} />
                    <InputForm name={"mobileNo"} label={"Contact Phone"} type={"number"} inputValue={inputValue} setInputValue={setInputValue} />
                    <FormControl isRequired>
                        <Flex py={3} align={"center"}>
                            <FormLabel w={{ base: "70%", sm: "60%", lg: "45%", xl: "30%" }} fontSize={13}>Contact Type&nbsp;:</FormLabel>
                            {/* <Input size={"sm"} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onChange={(e) => inputHandler(e.target.value)} /> */}
                            <Select variant={"flushed"} value={inputValue?.type} onChange={(e) => inputHandler("type", e.target.value)}>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Customer"}>Customer</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Employee"}>Employee</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Vender"}>Vender</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Self"}>Self</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Reseller"}>Reseller</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Refund"}>Refund</option>
                                <option style={{ background: "#111739", color: "rgba(255, 255, 255, 0.64)" }} value={"Expense"}>Expense</option>
                            </Select>
                        </Flex>
                    </FormControl>
                    <InputForm name={"email"} label={"Contact Email"} type={"email"} inputValue={inputValue} setInputValue={setInputValue} />

                    <Flex mt={5} justifyContent={"space-between"}>
                        <Button size={"sm"} colorScheme={"blue"} variant={"outline"} onClick={goBack}>Back</Button>
                        <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={addContactStatus === STATUS.FETCHING}>Add Contact</Button>
                    </Flex>
                </form>
            </Flex>
        </Flex >
    )
}

const InputForm = ({ require, name, label, limit, type, inputValue, setInputValue }) => {

    const inputHandler = (val) => {
        if (type === "number") {
            const numericValue = val.replace(/\D/g, '');

            if (numericValue.length <= (limit || 10)) {
                setInputValue(pre => ({ ...pre, [name]: numericValue }))
            }
        }
        else {
            setInputValue(pre => ({ ...pre, [name]: val }))
        }
    }

    return (
        <FormControl isRequired={require ? true : false}>
            <Flex py={3} align={"center"}>
                <FormLabel w={{ base: "70%", sm: "60%", lg: "45%", xl: "30%" }} fontSize={13}>{label}&nbsp;:</FormLabel>
                <Input size={"sm"} type={type || "text"} variant={"flushed"} value={inputValue?.[name]} onChange={(e) => inputHandler(e.target.value)} />
            </Flex>
        </FormControl>
    )
}