import { URIS, apiJson, zwitch } from ".";

export const apis = {
    loginApi: (payload) => apiJson.post(URIS.LOGIN, payload),
    changePassApi: (payload) => apiJson.put(URIS.CHANGE_PASS, payload),
    changePinApi: (payload) => apiJson.put(URIS.CHANGE_MPIN, payload),
    dashboardApi: (payload) => apiJson.get(URIS.DASHBOARD, payload),

    //Contact
    getContactApi: (payload) => apiJson.get(URIS.GET_CONTACT_ALL, payload),
    addContactApi: (payload) => apiJson.post(URIS.ADD_CONTACT, payload),
    getContactBanksApi: (payload) => apiJson.get(URIS.GET_CONTACT_BANKS + "/" + payload,),
    addBankApi: (payload) => apiJson.post(URIS.ADD_BANK, payload),
    deleteBankApi: (payload) => apiJson.delete(URIS.DELETE_BANK + "/" + payload),
    addUpiApi: (payload) => apiJson.post(URIS.ADD_UPI, payload),
    deleteUpiApi: (payload) => apiJson.delete(URIS.DELETE_UPI + "/" + payload),
    deleteAllFundsApi: (payload) => apiJson.get(URIS.DELETE_ALL_FUNDS + "/" + payload),

    accountBalanceApi: (payload) => zwitch.get(URIS.ACCOUNT_BALANCE, payload),
    verifyBankApi: (payload) => zwitch.post(URIS.BANK_VERIFICATION, payload),
    addBenificiaryBankApi: (payload) => zwitch.post(URIS.ADD_BENEFICIARY_BANK, payload),
    verifyUpiApi: (payload) => zwitch.post(URIS.UPI_VERIFICATION, payload),
    addBenificiaryUpiApi: (payload) => zwitch.post(URIS.ADD_BENEFICIARY_UPI, payload),
    bankTransferApi: (payload) => apiJson.post(URIS.BANK_TRANSFER, payload),
    upiTransferApi: (payload) => apiJson.post(URIS.UPI_TRANSFER, payload),
    verifyReferenceApi: (payload) => apiJson.post(URIS.VERIFY_REFER, payload),
    verifyMpinApi: (payload) => apiJson.post(URIS.VERIFY_MPIN, payload),
    transactionApi: (payload) => apiJson.post(URIS.TRANSACTION, payload),
}