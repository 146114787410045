import axios from "axios";
import { getLocalStorageItem } from "../../utils/LocalStorage";

const BASE_URL = "https://api.vijaylibrarypay.in/api";
const ZWITCH_URL = "https://api.zwitch.io/v1";

export const URIS = {
    LOGIN: "/admin/user/login",
    CHANGE_PASS: "/admin/user/changePassword",
    CHANGE_MPIN: "/admin/user/changeMpin",
    DASHBOARD: "/admin/common/dashboard",

    //Contact
    GET_CONTACT_ALL: "/admin/contact",
    ADD_CONTACT: "/admin/contact/add",
    GET_CONTACT_BANKS: "/admin/contact/allPaymentMode",
    ADD_BANK: "/admin/bank/add",
    DELETE_BANK: "/admin/bank",
    ADD_UPI: "/admin/upi/add",
    DELETE_UPI: "/admin/upi",
    DELETE_ALL_FUNDS: "/admin/contact/deleteAllData",

    ACCOUNT_BALANCE: "/accounts/va_BsaIwQtTZCUPLGJfWP3KWijSU/balance",
    BANK_VERIFICATION: "/verifications/bank-account",
    ADD_BENEFICIARY_BANK: "/accounts/va_BsaIwQtTZCUPLGJfWP3KWijSU/beneficiaries",
    UPI_VERIFICATION: "/verifications/vpa",
    ADD_BENEFICIARY_UPI: "/accounts/va_BsaIwQtTZCUPLGJfWP3KWijSU/beneficiaries",
    BANK_TRANSFER: "/admin/fund/transferBankPayment",
    UPI_TRANSFER: "/admin/fund/transferUPIPayment",
    VERIFY_MPIN: "/admin/user/verifyMpin",
    TRANSACTION: "/admin/fund/transaction",
    VERIFY_REFER: "/admin/fund/checkReference"
};

function createApiClient(contentType) {
    const accessToken = getLocalStorageItem("accessToken")

    const headers = {
        "Cache-Control": "no-cache",
        "ngrok-skip-browser-warning": "69420"
    };

    if (contentType === "multipart") {
        headers["Content-Type"] = "multipart/form-data";
    } else if (contentType === "json") {
        headers["Content-Type"] = "application/json";
    }
    if (accessToken) {
        headers["Authorization"] = "Bearer " + accessToken
    }

    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        headers,
        timeout: 600000,
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            let originalRequest = error.config;
            let isUnauthorized = error.response && error.response.status === 401;
            if (isUnauthorized && !originalRequest._retry && !originalRequest.headers._retry) {
                originalRequest._retry = true;
                return localStorage.clear()
            } else {
                return Promise.resolve(error);
            }
        }
    );

    axiosInstance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

function createZwitchApiClient() {

    const headers = {
        "Cache-Control": "no-cache",
        "ngrok-skip-browser-warning": "69420",
        "accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer ak_live_fnR6lEW2nRT5rtS3tftUu8ZMc5juVe36uyja:sk_live_H3ehoXQUCXVNo07QgVuzDX2HvQ8MxOgVXkFn"
    };

    const axiosInstance = axios.create({
        baseURL: ZWITCH_URL,
        headers,
        timeout: 600000,
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            let originalRequest = error.config;
            let isUnauthorized = error.response && error.response.status === 401;
            if (isUnauthorized && !originalRequest._retry && !originalRequest.headers._retry) {
                originalRequest._retry = true;
            } else {
                return Promise.resolve(error);
            }
        }
    );

    axiosInstance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}
const apiMultipart = createApiClient("multipart");
const apiJson = createApiClient("json");
const zwitch = createZwitchApiClient();

export { apiMultipart, apiJson, zwitch };
