export const setLocalStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }
    return null;
};

export const removeLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
    }
}